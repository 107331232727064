// Contact.tsx
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import useDevice from '../hooks/useDevice'

const Contact: React.FC = () => {
  const { isMobileView } = useDevice()

  const [startAnimation, setStartAnimation] = useState(false)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.7,
  })

  useEffect(() => {
    if (inView) {
      setStartAnimation(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={`w-full ${isMobileView ? 'mt-10 px-4' : 'flex flex-row-reverse'}`}>
      <div
        className={`bg-[#00000045] rounded-lg opacity-0 sm:w-full xl:w-2/3 xl:max-w-[600px] xl:mt-20 ${startAnimation ? 'slide-left-to-right' : ''}`}
      >
        <section className='rounded-lg'>
          <h2>Contact</h2>
          <p>
            LinkedIn:{' '}
            <a
              href='https://www.linkedin.com/in/thorsten-maus-70148614/'
              className='text-blue-300 cursor-pointer underline'
            >
              Thorsten Maus
            </a>
          </p>
          <p>Email: thorsten@reef-digital.com</p>

          {/* Contact form or details */}
        </section>
      </div>

      {!isMobileView && (
        <div
          className={`h-32 w-full ml-40 border-l-2 border-b-2 border-white rounded-bl-lg opacity-0 ${startAnimation ? 'fade-in' : ''}`}
        />
      )}
    </div>
  )
}

export default Contact
